<template>
  <div>
    <heads :msg="title_msg" :states="1"></heads>
    <div class="search">
      <div class="searchLeft" @click="showPopup(1)">
        <img src="../../static/giftIndex/flRed.png" alt="" />
        <p>分类</p>
      </div>
      <div class="searchRight">
        <input
          v-model="iptValue"
          type="text"
          placeholder="搜索礼品名称"
          @keyup.enter="clickSearch()"
        />
        <img
          src="../../static/giftIndex/search.png"
          alt=""
          @click="clickSearch()"
        />
      </div>
    </div>

    <div class="list">
      <div
        v-for="(item, index) in zlList"
        :key="index"
        class="list_item"
        @click="zlDetail(item.id)"
      >
        <div class="item_top">
          <img :src="item.img" alt="" />
        </div>
        <div class="item_bottom">
          <div class="title_box">
            <p class="title">{{ item.gift_name }}</p>
          </div>
          <div class="zlBtm" style="justify-content: space-between">
            <div style="display: flex;align-items: center;">
              <img class="mmon" src="../../static/giftIndex/mmon.png" alt="">
              <p class="myPrice">
                <span style="display: inline-block;margin-right: 2px">{{item.quantity}}</span>
                <!-- <span style="font-size: 15px">起</span> -->
              </p>
            </div>
            <p class="stock" style="padding:0 !important;">库存<span>{{ item.num }}</span></p>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="zl_main">
      <div v-for="(item,index) in zlList" :key="index" class="zl_mains" @click="zlDetail(item.id)">
        <div class="zl_mainsTop">
          <img class="giftImg" :src="item.img" alt="">
        </div>
        <div class="zl_mainsBottom">
          <p class="commodityTitle">{{ item.gift_name }}</p>
          <div class="zlBtm">
            <img class="mmon" src="../../static/giftIndex/mmon.png" alt="">
            <p class="myPrice">{{ item.quantity }}</p>
            <p class="stock">库存<span>{{ item.num }}</span></p>
          </div>
        </div>
      </div>
    </div> -->

    <van-popup v-model="show" position="top" round :style="{ height: '60%' }">
      <div class="popupAll">
        <div class="popupHeader">
          <img
            src="@/static/images/my_index/back_black.png"
            alt=""
            @click="showPopup(2)"
          />
          <p class="popupTitle">选择分类</p>
          <span></span>
        </div>
        <div class="popupSearch">
          <div class="Search_ipt">
            <div class="SearchBox">
              <span
                v-for="(item, index) in flList"
                :key="index"
                class="pushClass"
                @click="delItem(item, index)"
                >{{ item.name
                }}<van-icon name="cross" style="padding: 0 0 0 8px"
              /></span>
            </div>
            <span class="searchSpan" @click="showPopup(2)">取消</span>
          </div>
        </div>
        <div class="partition2"></div>
        <div class="classification">
          <p class="classification_p">分类</p>
          <div class="classification_item">
            <p
              v-for="(item, index) in CategoryList"
              :key="index"
              :class="{ pGrey: item.checked === 0, pRed: item.checked === 1 }"
              @click="CategoryListPush(item, index)"
            >
              {{ item.name }}
            </p>
          </div>
        </div>
        <!-- <div class="classification">
          <p class="classification_p">价格区间</p>
          <div class="classification_item">
            <p>筑龙原创</p>
            <p>筑龙原创</p><p>筑龙原创</p><p>筑龙原创</p><p>筑龙原创</p>
          </div>
        </div> -->
        <div class="popupBtn">
          <div class="popupBtn_item">
            <button class="Reset" @click="Resets()">重置</button>
            <button class="complete" @click="showPopup(3)">
              完成<span>(已选{{ flList.length }})</span>
            </button>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import heads from "@/compontens/heads.vue";
import { get_gift, getCategory } from "@/api/my_user.js";
// import { Toast } from 'vant'
export default {
  components: {
    heads,
  },
  data() {
    return {
      title_msg: "筑龙原创礼品",
      zlList: [],
      show: false,
      flList: [],
      CategoryList: [],
      iptValue: "",
    };
  },
  mounted() {
    this.getMsg();
    this.get_Category();
  },
  methods: {
    Resets() {
      this.flList = [];
      this.CategoryList.forEach((item) => {
        if (item.checked === 1) {
          item.checked = 0;
        }
      });
    },
    delItem(v, i) {
      v.checked = 0;
      this.flList.splice(i, 1);
    },
    // 搜索框展示内容
    CategoryListPush(item, index) {
      if (!item.checked) {
        item.checked = 1;
        this.flList.push(item);
      } else {
        item.checked = 0;
        this.flList.forEach((items, i) => {
          if (items.id === item.id) {
            this.flList.splice(i, 1);
          }
        });
      }
    },
    // 获取分类列表
    get_Category() {
      getCategory().then((res) => {
        res.result.forEach((item) => {
          item.checked = 0;
        });
        // console.log(res.result)
        this.CategoryList = res.result;
      });
    },
    clickSearch() {
      if (this.iptValue === "") {
        this.getMsg();
        // Toast('缺少关键词')
      } else {
        var params = {
          isZL: 1,
          keyword: this.iptValue,
        };
        get_gift(params).then((res) => {
          this.zlList = res.result.list;
        });
      }
    },
    zlDetail(id) {
      console.log(id);
      this.$router.push({
        path: '/gift/giftExchange',
        query: {
          id: id,
        },
      });
    },
    getMsg() {
      get_gift().then((res) => {
        this.zlList = res.result.zlProduct;
      });
    },
    showPopup(type) {
      if (type === 1) {
        this.show = true;
      } else if (type === 2) {
        this.show = false;
      } else if (type === 3) {
        this.show = false;
        const idList = [];
        this.flList.forEach((item, index) => {
          idList.push(item.id);
        });
        // var params = {
        //   category_id: JSON.stringify(idList),
        //   isZL: 1
        // }
        var params = {};
        if (idList && idList.length > 0) {
          params.category_id = JSON.stringify(idList);
          params.gift_source = 1;
        }
        get_gift(params).then((res) => {
          this.zlList = res.result.zlProduct;
          // this.zlList = res.result.list
        });
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = "#f7f7f7";
    next();
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = "";
    next();
  },
};
</script>

<style lang="scss" scoped>
.list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
}
.list_item {
  width: 330px;
  margin: 30px 0 0 30px;
  border-radius: 10px 10px 10px 10px;
  background-color: white;
}
.item_top {
  width: 100%;
  height: 330px;
  img {
    width: 100%;
    height: 330px;
    border-radius: 10px 10px 0 0;
  }
}
.item_bottom {
  padding: 16px 16px 0 16px;
}
.title_box {
  height: 90px;
}
.title {
  color: #333333;
  font-size: 30px;
  // height: 90px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  -webkit-box-orient: vertical;
}
.zlBtm {
  display: flex;
  align-items: center;
  margin: 18px 0 18px 0;
  height: 30px;
}
.mmon {
  width: 26px;
  height: 30px;
}
.myPrice {
  color: #ee2e2e;
  font-size: 36px;
  padding: 0 0 0 10px;
}
.stock {
  color: #999999;
  font-size: 28px;
  padding: 0 0 0 10px;
}

// .mmon{
//   width: 26px;
//   height: 30px;
// }
// .myPrice{
//   color: #FF0000;
//   font-size: 32px;
//   font-weight: 600;
//   padding: 0 0 0 10px;
// }
// .stock{
//   color: #888888;
//   font-size: 26px;
//   padding: 0 0 0 10px;
// }
// .zlBtm{
//   display: flex;
//   align-items: center;
//   margin: 10px 0 0 0 ;
// }
.commodityTitle {
  color: #222222;
  font-size: 30px;
  font-weight: 600;
  line-height: 50px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  min-height: 100px;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
// .giftImg{
//   width: 330px;
//   height: 330px;
//   background: antiquewhite;
//   border-radius: 10px;
// }
.zl_mains {
  width: 330px;
  margin: 30px 0 0 23px;
}
.zl_main {
  width: 98%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-around;
}
.search {
  // width: 100%;
  // height: 110px;
  // display: flex;
  // align-items: center;
  // background: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  height: 110px;
  background: #fff;
}
.searchLeft img {
  width: 38px;
  height: 38px;
}
.searchLeft {
  width: 130px;
  display: flex;
  align-items: center;
  // margin: 11px 0 0 30px;
  margin-left: 30px;
  p {
    font-size: 30px;
    color: #333333;
    padding: 0 0 0 16px;
  }
}
.searchRight {
  // width: 561px;
  width: calc(100% - 90px - 130px);
  height: 64px;
  position: relative;
  margin: 0 0 0 30px;
  img {
    width: 25px;
    height: 25px;
    position: absolute;
    left: 30px;
    top: 20px;
    // top: 21px;
  }
}
.searchRight input {
  // width: 480px;
  width: calc(100% - 70px);
  height: 64px;
  // height: 100%;
  border-radius: 32px;
  padding: 0 0 0 70px;
  color: #999999;
  letter-spacing: 3px;
  font-size: 24px;
  background: #eeeeee;
  display: flex;
  align-items: center;
}

.popupAll {
  width: 100%;
  height: 100%;
  position: relative;
}
.popupHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 0 0;
  img {
    width: 68px;
    height: 68px;
    margin-left: 10px;
  }
}
.popupTitle {
  font-size: 36px;
  color: #222222;
  // font-weight: 600;
  font-weight: bold;
  padding: 0 30px 0 0;
  letter-spacing: 3px;
  text-align: center;
}
.popupSearch {
  margin: 30px 0 0 0;
}
.Search_ipt {
  width: 91%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.SearchBox {
  width: 620px;
  min-height: 56px;
  border-radius: 30px;
  background: #f7f7f7;
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
}
.pushClass {
  height: 44px;
  background: #fff;
  display: inline-block;
  margin: 0 0 10px 15px;
  line-height: 44px;
  // padding: 0 13px 0 13px;
  padding: 0 13px 0 13px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.searchSpan {
  // color: #565759;
  // font-size: 24px;
  // padding: 13px 0 0 10px;
  color: #565759;
  font-size: 24px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  display: inline-block;
  width: 80px;
  height: 64px;
  line-height: 64px;
  text-align: center;
}
.partition2 {
  width: 100%;
  height: 15px;
  background: #f5f5f5;
  margin: 30px 0 0 0;
}
.classification_p {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #000000;
  margin: 25px 30px;
}
.classification_item {
  display: flex;
  width: 93%;
  margin: 0 auto;
  flex-wrap: wrap;
  // justify-content: space-between;
  .pGrey {
    // width: 152px;
    width: 148px;
    background: #f7f7f7;
    // border-radius: 6px;
    border-radius: 10px;
    color: #666666;
    text-align: center;
    line-height: 62px;
    border: 2px solid #f7f7f7;
    margin-top: 10px;
    margin-left: 20px;
  }
  .pRed {
    width: 148px;
    // width: 152px;
    // border-radius: 6px;
    border-radius: 10px;
    background: #ffefef;
    border: 2px solid #ff7576;
    color: #ee2e2f;
    text-align: center;
    line-height: 62px;
    margin-top: 10px;
    margin-left: 20px;
  }
}
.popupBtn_item {
  width: 93%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 60px;
  left: 30px;
  .Reset {
    width: 330px;
    height: 80px;
    color: #222222;
    font-size: 30px;
    letter-spacing: 2px;
    background: #f3f3f3;
    border-radius: 10px;
  }
  .complete {
    width: 330px;
    height: 80px;
    background: #ee2e2e;
    border: 1px solid #ee2e2e;
    // border-radius: 6px;
    border-radius: 10px;
    font-size: 30px;
    color: #fff;
    letter-spacing: 2px;
  }
}
.popupBtn {
  width: 100%;
}
</style>
